import React from 'react'

class AddonAfter extends React.PureComponent {
    timer = null
    waitfor = 60

    constructor(props) {
        super(props)
        this.state = {
            restSeconds: 0,
            loading: false
        }
    }

    componentWillUnmount () {
        this.timer && clearTimeout(this.timer)
    }

    sendCode = async () => {
        if (this.state.loading) return
        const { getCode } = this.props
        await this.setState({ loading: true })
        const ret = await getCode()
        this.setState({ loading: false })
        if (ret !== 1) {
            return
        }
        this.setState({ restSeconds: this.waitfor }, () => {
            this.runTimer()
        })
    }

    runTimer () {
        this.timer && clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.setState((prevState) => {
                if (prevState.restSeconds < 1) {
                    clearTimeout(this.timer)
                } else {
                    this.runTimer()
                }
                return {
                    restSeconds: prevState.restSeconds - 1
                }
            })
        }, 1000)
    }

    render () {
        const { restSeconds, loading } = this.state
        return restSeconds > 0 ? (
            <span className='seconds_text'>{ restSeconds } S</span>
        ) : (
            loading ? 
                <span className='loading_text'>
                    发送中...
                </span>
            :
                <span className='send_text' onClick={this.sendCode}>
                    发送验证码
                </span>
        )
    }
}

export default AddonAfter
