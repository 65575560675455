import { AuthDef } from './index.module'
// import { REQUEST_METHOD } from '@src/http/http'
export default class {
    // 普通登录
    Login({
        POST_FORM
    }, params) {
        // return FORM_POST(urls.login, params)
        const url = AuthDef.main.url;
        return POST_FORM(url, params);
    }

    // 获取验证码
    sendCode({
        POST_FORM
    }, params) {
        const url = `/sendCode`
        return POST_FORM(url, params)
    }

    // 验证码登录
    codeLogin({
        POST_FORM
    }, params) {
        const url = `/client/mobile/token`
        return POST_FORM(url, params)
    }
}