import { AuthDef } from './index.module'
// import { REQUEST_METHOD } from '@src/http/http'
export default class {
    [AuthDef.delete.serviceName]({
        DELETE
    }, id) {
        // return FORM_POST(urls.login, params)
        const url = `${AuthDef.delete.url}/${id}`
        return DELETE(url, id)
    }
}