// import { AuthDef } from './index.module'
// import { REQUEST_METHOD } from '@src/http/http'

const rUrl = url => `/client/totalVolumeStatistics/${url}`

export default class {
    getHomeDetails({
        POST
    }, params) {
        // return FORM_POST(urls.login, params)
        const url = '/client/dashboard/detail'
        return POST(url, params)
    }

    getHomeMaterials({
        GET
    }) {
        const url = rUrl('getMaterialName')
        return GET(url)
    }

    getHomeReceiptData({
        GET
    }, params) {
        const url = rUrl('getReceiptData')
        return GET(url, params)
    }

    getHomeShipmentData({
        GET
    }, params) {
        const url = rUrl('getShipmentData')
        return GET(url, params)
    }
}