import React from 'react';
import { HandsomeSpin } from '@src/components/handsome'
import { 
    viewType,
    serviveMethod 
} from '@src/config';
/**
 * 模块namespace definition，
 */
export const path = '/home';


const rUrl = url => `/client/totalVolumeStatistics/${url}`


export const AuthDef = {
    main: {
        id: path,
        name: '首页',
        parent: null,
        type: viewType.page,
        path,
        serviceName: `${path}/getUserDashboard`,
        method: serviveMethod.GET,
        url: '/client/dashboard/getUserDashboard',
        sort: 1
    },
    set: {
        id: `${path}/set`,
        name: '修改看板配置',
        parent: path,
        type: viewType.view,
        // path: path, // 不填则默认id
        serviceName: `${path}/set`, // 不填则默认id
        method: serviveMethod.POST_QUERY,
        url: '/client/dashboard/updateDashboardAuthority',
        sort: 1
    },
    transport: {
        id: `${path}/transport`,
        name: '我的运输',
        parent: path,
        type: viewType.view,
        sort: 2
        // path: path, // 不填则默认id
        // serviceName: ``, // 不填则默认id
        // method: serviveMethod.GET,
        // url: '/client/dashboard/getUserDashboard',
    },
    warehouse: {
        id: `${path}/warehouse`,
        name: '我的仓库',
        parent: path,
        type: viewType.view,
        sort: 3
        // path: path, // 不填则默认id
        // serviceName: ``, // 不填则默认id
        // method: serviveMethod.GET,
        // url: '/client/dashboard/getUserDashboard',
    },
    kpi: {
        id: `${path}/kpi`,
        name: 'KPI考核',
        parent: path,
        type: viewType.view,
        sort: 4,
        // path: path, // 不填则默认id
        // serviceName: ``, // 不填则默认id
        // method: serviveMethod.GET,
        // url: '/client/dashboard/getUserDashboard',
        children: [
            {
                id: `${path}/kpi/kpi1`,
                name: '提货及时率',
                parent: `${path}/kpi`,
                type: viewType.view,
            },
            {
                id: `${path}/kpi/kpi2`,
                name: '送货及时率',
                parent: `${path}/kpi`,
                type: viewType.view,
            },
            {
                id: `${path}/kpi/kpi3`,
                name: '回单率',
                parent: `${path}/kpi`,
                type: viewType.view,
            },
            {
                id: `${path}/kpi/kpi4`,
                name: '收货及时率',
                parent: `${path}/kpi`,
                type: viewType.view,
            },
            {
                id: `${path}/kpi/kpi5`,
                name: '发货及时率',
                parent: `${path}/kpi`,
                type: viewType.view,
            },
            {
                id: `${path}/kpi/kpi6`,
                name: '库存准确率',
                parent: `${path}/kpi`,
                type: viewType.view,
            },
            {
                id: `${path}/kpi/kpi7`,
                name: '时效达成率',
                parent: `${path}/kpi`,
                type: viewType.view,
            },
        ]
    },
    volume: {
        id: `${path}/volume`,
        name: '体量统计',
        parent: path,
        type: viewType.view,
        sort: 5
        // path: path, // 不填则默认id
        // serviceName: ``, // 不填则默认id
        // method: serviveMethod.GET,
        // url: '/client/dashboard/getUserDashboard',
    },
    traffic: {
        id: `${path}/traffic`,
        name: '运量统计',
        parent: path,
        type: viewType.view,
        sort: 6
        // path: path, // 不填则默认id
        // serviceName: ``, // 不填则默认id
        // method: serviveMethod.GET,
        // url: '/client/dashboard/getUserDashboard',
    },
    remind: {
        id: `${path}/remind`,
        name: '预警提醒',
        parent: path,
        type: viewType.view,
        sort: 7
        // path: path, // 不填则默认id
        // serviceName: ``, // 不填则默认id
        // method: serviveMethod.GET,
        // url: '/client/dashboard/getUserDashboard',
    },
    receiptExport: {
        id: `${path}/receiptExport`,
        name: '体量收货导出',
        parent: path,
        type: viewType.view,
        sort: 8,
        serviceName: `${path}/receiptExport`,
        method: serviveMethod.GET_FILE,
        url: rUrl('exportReceiptData')
    },
    shipmentExport: {
        id: `${path}/shipmentExport`,
        name: '体量出货导出',
        parent: path,
        type: viewType.view,
        sort: 9,
        serviceName: `${path}/shipmentExport`,
        method: serviveMethod.GET_FILE,
        url: rUrl('exportShipmentData')
    },
}

/**
 * 描述service请求方式
 *
 * @class Service
 */
export class Service {
    // [AuthDef.main.serviceName]({ GET }) {
    //     console.log('GET', GET)
    //     return GET(AuthDef.main.url)
    // }
}


const LoadPage = React.lazy(() => import(/* webpackChunkName:"home" */ './index.jsx'));
/**
 * 描述代码分割后页面组件
 *
 * @export function 表示页面入口组件
 * @returns React element
 */
export default function Page () {
    return (
        <React.Suspense fallback={<HandsomeSpin />}> 
            <LoadPage />
        </React.Suspense>
    );
}