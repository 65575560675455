import React, { Component } from 'react'
import { connect } from 'dva'
import TweenOne from 'rc-tween-one'
import { Form, Icon, Input, Button, Checkbox, message } from 'antd'
import { withRouter } from 'dva/router'
import { phoneRule } from '@src/libs/rule'
import { md5hash } from '@src/libs/logic'
import loginBackground from '@src/assets/image/loginBg.png'
import rApi from '@src/http'
import AddonAfter from './addon_after'
import Main from './main'

// import './index.less'

const tweenoneStyle = {
    width: '100%',
    height: '100%',
    transform: 'translateX(0)'
}
const pageStyle = {
    // position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    background: `url(${loginBackground}) no-repeat`,
    backgroundSize: 'cover'
}

@connect(({ global, loading }) => {
    return {
        ...global,
        loading: loading.effects['global/login']
    }
}) 
class Login extends Component { 
    constructor(props) {
        super(props)
        this.state = {
            didMount: false,
            x: 0,
            transform: 'scale(0.98, 0.98)',
            loginType: 'client'
        }
    }

    componentDidMount() {
        this.props.dispatch({
            type: 'global/logout'
        })
        setTimeout(() => {
            this.setState({
                didMount: true
            })
            // this.props.dispatch({
            //     type: 'global/logout'
            // })
        }, 10)
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    onMouseEnter = () => {
        this.setState({
            transform: 'scale(1, 1)'
        })
        // this.updateTweenData();
    }

    onMouseLeave = () => {
        // this.gather && this.updateTweenData();
        this.setState({
            transform: 'scale(0.98, 0.98)'
        })
        // this.interval = ticker.interval(this.updateTweenData, this.intervalTime);
    }

    submit = async (e) => {
        e.preventDefault()
        try {
            const { loginType } = this.state
            const {
                dispatch,
                form
            } = this.props
            const data = await form.validateFields()
            let payload = {
                type: loginType
            }
            if (loginType === 'client') {
                payload = {
                    ...payload,
                    username: data.username,
                    password: md5hash(data.password),
                    grant_type: 'password'
                }
            } else {
                if (!phoneRule.test(data.phone)) {
                    message.error(phoneRule.message)
                    return
                }
                if (!data.code) {
                    message.error('验证码不能为空')
                    return
                }
                payload = {
                    ...payload,
                    phone: data.phone,
                    code: data.code
                }
            }
            await dispatch({
                type: 'global/login',
                payload
            })
            this.setState({
                x: 0 - document.body.offsetWidth
            })
        } catch (error) {
            console.log(error)
        }
    }

    changeLoginType = (loginType) => {
        if (this.state.loginType === loginType) return
        this.setState({ loginType })
    }

    getCode = async () => {
        try {
            const { form } = this.props
            const data = await form.validateFields()
            if (phoneRule.test(data.phone)) {
                await rApi.sendCode({ phone: data.phone })
                return 1
            } else {
                message.error(phoneRule.message)
                throw Error(phoneRule.message)
            }
        } catch (error) {
            console.log('error', error)
            return 0
        }
    }

    render() {
        const { loading } = this.props
        const { 
            didMount, 
            transform,
            x,
            loginType
        } = this.state
        const { getFieldDecorator } = this.props.form
        // console.log('login', this.props)
        return (
            <TweenOne
                animation={{
                    x: x,
                    ease: 'easeOutSine',
                    // scale: 0.5,
                    // rotate: 120,
                    // yoyo: true, // demo 演示需要
                    // repeat: -1, // demo 演示需要
                    duration: 500
                }}
                style={tweenoneStyle}
            >
                <div className='login_page' style={pageStyle}>
                    <div className='login-logo'>
                        <img src="" alt=""/>
                        <h1>客户平台</h1>
                    </div>
                    <Form
                        style={didMount ? { transform } : {}} 
                        onSubmit={this.submit} 
                        className="login-form"
                    >
                        <h2 className='title'>
                            登录
                        </h2>
                        <ul className={`tab_title ${loginType === 'client' ? 'left' : 'right'}`}>
                            <li className={loginType === 'client' ? 'active' : ''} onClick={() => this.changeLoginType('client')}>客户登录</li>
                            <li className={loginType === 'order' ? 'active' : ''} onClick={() => this.changeLoginType('order')}>订单查询</li>
                        </ul>
                        {
                            loginType === 'client'
                                ?
                            (<React.Fragment>
                                <Form.Item>
                                    {
                                        getFieldDecorator('username', {
                                            rules: [{ required: false }],
                                        })(
                                            <Input
                                                className='form_user'
                                                addonAfter={<Icon type="user" />}
                                                disabled={loading}
                                                placeholder="请输入用户名"
                                            />
                                        )
                                    }
                                </Form.Item>
                                <Form.Item>
                                    {
                                        getFieldDecorator('password')(
                                            <Input
                                                className='form_pwd'
                                                addonAfter={<Icon type="lock" />}
                                                type="password"
                                                disabled={loading}
                                                placeholder="请输入密码"
                                            />
                                        )
                                    }
                                </Form.Item>
                                <div className='form_remember'>
                                    {
                                        getFieldDecorator('remember', {
                                            valuePropName: 'checked',
                                            initialValue: true,
                                        })(
                                            <Checkbox>记住密码</Checkbox>
                                        )
                                    }
                                </div>
                            </React.Fragment>)
                                :
                            (<React.Fragment>
                                <Form.Item>
                                    {
                                        getFieldDecorator('phone', {
                                            rules: [{ required: false }],
                                        })(
                                            <Input
                                                className='form_phone'
                                                addonAfter={<Icon type="mobile" />}
                                                disabled={loading}
                                                placeholder="请输入手机号"
                                            />
                                        )
                                    }
                                </Form.Item>
                                <Form.Item style={{ marginBottom: 46 }}>
                                    {
                                        getFieldDecorator('code', {
                                            rules: [{ required: false }]
                                        })(
                                            <Input
                                                className='form_code'
                                                addonAfter={
                                                    <AddonAfter
                                                        getCode={this.getCode}
                                                    />
                                                }
                                                disabled={loading}
                                                placeholder="验证码"
                                            />
                                        )
                                    }
                                </Form.Item>
                            </React.Fragment>)
                        }
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ borderRadius: 2 }}
                                loading={loading}
                                block
                            >
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </TweenOne>
        );
    }
}
 
// export default withRouter(Form.create({ name: 'login' })(Login));
export default withRouter(Main);