import React from 'react';
import { HandsomeSpin } from '@src/components/handsome'
import { 
    viewType,
    // serviveMethod
} from '@src/config';
/**
 * 模块namespace definition，
 */
export const path = 'login';

/** 
 * 权限描述： AuthDef => Authority Definition
*/
export const AuthDef = {
    main: {
        id: path,
        name: 'Login',
        parent: null,
        type: viewType.pageHide,
        path: `/${path.toLowerCase()}`,
        // serviceName: `${modelName}/getData`,
        // method: serviveMethod.POST_FORM,
        url: '/client/web/token',
        sort: 10
    }
}

/**
 * 描述service请求方式
 *
 * @class Service
 */
export class Service {}

const LoadPage = React.lazy(() => import(/* webpackChunkName:"login" */ './index.jsx'));
/**
 * 描述代码分割后页面组件
 *
 * @export function 表示页面入口组件
 * @returns React element
 */
export default function Page () {
    return (
        // Displays <Spinner> until OtherComponent loads
        <React.Suspense fallback={<HandsomeSpin />}> 
            <LoadPage />
        </React.Suspense>
    );
}